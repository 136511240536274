import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: left;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
  }

  span {
    color: var(--primary);
  }

  a {
    color: white;
  }

  p {
    line-height: 1.6em;
  }
`

const band_person = () => {
  return (
    <>
      <Seo title="Jagoda Szydłowska" />
      <Layout>
        <SimpleBanner title="Jagoda Szydłowska">
          <StaticImage
            className="banner__image"
            src="../../images/band/jagoda-thumb.jpg"
          />
        </SimpleBanner>
        <Container>
          <p>
          To osoba z wieloletnim doświadczeniem wokalnym i obyciem scenicznym. Od lat śpiewa i występuje na różnych eventach i koncertach. Jej ostatnim osiągnięciem było wejście do finału w konkursie radia RMF FM „Twoje 5 min” z utworem „Sweet Dreams” oraz udział w programie „Twoja Twarz Brzmi Znajomo”. Jagoda jest również laureatką konkursu „Śpiewać każdy może” w krakowskiej Rotundzie. Pisze własne piosenki i teksty. Pochodzi z rodziny muzyków, dlatego scena to jej drugi dom. Ukończyła szkołę muzyczna na flecie poprzecznym i uczęszczała do Krakowskiej Szkoły Jazzu i Muzyki Rozrywkowej w klasie wokalnej. Jagodę charakteryzuje mocny głos o ciepłej barwie, doskonale odnajduje się w balladach, utworach rockowych i tanecznych.Scena ją mocno ogranicza, dlatego Jagodę wielokrotnie spotkacie na parkiecie tańcząc i śpiewając. Jagoda to prawdziwy wulkan energii, której baterie nieustannie się ładują. Poza sceną to niezwykle charyzmatyczna i kreatywna osoba z poczuciem humoru. Na codzień trener personalny.
          </p>
        </Container>
      </Layout>
    </>
  )
}

export default band_person
